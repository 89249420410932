<template>
  <div class="BasePagination" tabindex="1" >
    <pagination
      tabindex="1"
      background
      layout="prev, pager, next"
      v-bind="$attrs"
      :pager-count="5"
      @current-change="e => $emit('current-change', e)"
      prev-text="<"
      next-text=">"
    >
    </pagination>
  </div>
</template>

<script>
import { Pagination } from 'element-ui'
import $ from 'jquery'

export default {
  components: { Pagination },
  mounted(){
    $(".btn-prev").attr('title','上一頁');
    $(".btn-next").attr('title','下一頁');
    // $(".btn-prev").removeAttr('disabled');
  }
}
</script>

<style lang="scss">
.BasePagination {
  text-align: center;
  margin: 0 auto;

  & .el-pagination button,
  & .el-pagination.is-background .btn-next,
  .el-pagination.is-background .btn-prev,
  .el-pagination.is-background .el-pager li {
    border: 1px solid #595757;
    background-color: white;
  }

  .btn-prev:focus, .btn-next:focus{
    outline: 1px solid #595757 !important;
  }

  .btn-prev:hover, .btn-next:hover{
    color:#409EFF;
  }

  & .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #595757;
  }

  & .el-pagination .el-pager li.more {
    border: none;
  }

  & .el-pagination button,
  .el-pagination span:not([class*='suffix']) {
    min-width: 30px;
  }
}
</style>
